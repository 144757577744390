/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAsset = /* GraphQL */ `
  mutation CreateAsset(
    $input: CreateAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    createAsset(input: $input, condition: $condition) {
      id
      uuid
      createdAt
      updatedAt
      status
      customerId
      deviceId
      name
      userGroups
      adminGroups
      description
      version
      unitNumber
      make
      model
      year
      capacity
      activatedOn
      deactivatedOn
      toolType
      size
      annualInspection
      type {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      subType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      dimensions {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      supplier {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      eventTs
      receivedTs
      firstCheckedIn
      coordinate {
        latitude
        longitude
        __typename
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      projectId
      movingStatus
      movingCoordinate {
        latitude
        longitude
        __typename
      }
      movingTs
      movingStartTs
      movedCoordinate {
        latitude
        longitude
        __typename
      }
      movedTs
      movedProjectId
      __typename
    }
  }
`;
export const updateAsset = /* GraphQL */ `
  mutation UpdateAsset(
    $input: UpdateAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    updateAsset(input: $input, condition: $condition) {
      id
      uuid
      createdAt
      updatedAt
      status
      customerId
      deviceId
      name
      userGroups
      adminGroups
      description
      version
      unitNumber
      make
      model
      year
      capacity
      activatedOn
      deactivatedOn
      toolType
      size
      annualInspection
      type {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      subType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      dimensions {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      supplier {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      eventTs
      receivedTs
      firstCheckedIn
      coordinate {
        latitude
        longitude
        __typename
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      projectId
      movingStatus
      movingCoordinate {
        latitude
        longitude
        __typename
      }
      movingTs
      movingStartTs
      movedCoordinate {
        latitude
        longitude
        __typename
      }
      movedTs
      movedProjectId
      __typename
    }
  }
`;
export const deleteAsset = /* GraphQL */ `
  mutation DeleteAsset(
    $input: DeleteAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    deleteAsset(input: $input, condition: $condition) {
      id
      uuid
      createdAt
      updatedAt
      status
      customerId
      deviceId
      name
      userGroups
      adminGroups
      description
      version
      unitNumber
      make
      model
      year
      capacity
      activatedOn
      deactivatedOn
      toolType
      size
      annualInspection
      type {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      subType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      dimensions {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      supplier {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      eventTs
      receivedTs
      firstCheckedIn
      coordinate {
        latitude
        longitude
        __typename
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      projectId
      movingStatus
      movingCoordinate {
        latitude
        longitude
        __typename
      }
      movingTs
      movingStartTs
      movedCoordinate {
        latitude
        longitude
        __typename
      }
      movedTs
      movedProjectId
      __typename
    }
  }
`;
export const createAssetHistory = /* GraphQL */ `
  mutation CreateAssetHistory(
    $input: CreateAssetHistoryInput!
    $condition: ModelAssetHistoryConditionInput
  ) {
    createAssetHistory(input: $input, condition: $condition) {
      assetId
      eventTs
      uuid
      deviceId
      unitNumber
      customerId
      receivedTs
      coordinate {
        latitude
        longitude
        __typename
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      userGroups
      adminGroups
      projectId
      assetType {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssetHistory = /* GraphQL */ `
  mutation UpdateAssetHistory(
    $input: UpdateAssetHistoryInput!
    $condition: ModelAssetHistoryConditionInput
  ) {
    updateAssetHistory(input: $input, condition: $condition) {
      assetId
      eventTs
      uuid
      deviceId
      unitNumber
      customerId
      receivedTs
      coordinate {
        latitude
        longitude
        __typename
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      userGroups
      adminGroups
      projectId
      assetType {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssetHistory = /* GraphQL */ `
  mutation DeleteAssetHistory(
    $input: DeleteAssetHistoryInput!
    $condition: ModelAssetHistoryConditionInput
  ) {
    deleteAssetHistory(input: $input, condition: $condition) {
      assetId
      eventTs
      uuid
      deviceId
      unitNumber
      customerId
      receivedTs
      coordinate {
        latitude
        longitude
        __typename
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      userGroups
      adminGroups
      projectId
      assetType {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssetMovementCoordinate = /* GraphQL */ `
  mutation CreateAssetMovementCoordinate(
    $input: CreateAssetMovementCoordinateInput!
    $condition: ModelAssetMovementCoordinateConditionInput
  ) {
    createAssetMovementCoordinate(input: $input, condition: $condition) {
      movementId
      source
      index
      eventTs
      latitude
      longitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssetMovementCoordinate = /* GraphQL */ `
  mutation UpdateAssetMovementCoordinate(
    $input: UpdateAssetMovementCoordinateInput!
    $condition: ModelAssetMovementCoordinateConditionInput
  ) {
    updateAssetMovementCoordinate(input: $input, condition: $condition) {
      movementId
      source
      index
      eventTs
      latitude
      longitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssetMovementCoordinate = /* GraphQL */ `
  mutation DeleteAssetMovementCoordinate(
    $input: DeleteAssetMovementCoordinateInput!
    $condition: ModelAssetMovementCoordinateConditionInput
  ) {
    deleteAssetMovementCoordinate(input: $input, condition: $condition) {
      movementId
      source
      index
      eventTs
      latitude
      longitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssetMovementReport = /* GraphQL */ `
  mutation CreateAssetMovementReport(
    $input: CreateAssetMovementReportInput!
    $condition: ModelAssetMovementReportConditionInput
  ) {
    createAssetMovementReport(input: $input, condition: $condition) {
      id
      uuid
      assetId
      startTs
      stopTs
      state
      onRoadDistance
      offRoadDistance
      totalDistanceTraveled
      movedToCoordinate {
        latitude
        longitude
        __typename
      }
      movedFromCoordinate {
        latitude
        longitude
        __typename
      }
      prevProjectId
      destProjectId
      customerId
      firstMove
      userGroups
      adminGroups
      assetTypeId
      assetType {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      assetSubTypeId
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssetMovementReport = /* GraphQL */ `
  mutation UpdateAssetMovementReport(
    $input: UpdateAssetMovementReportInput!
    $condition: ModelAssetMovementReportConditionInput
  ) {
    updateAssetMovementReport(input: $input, condition: $condition) {
      id
      uuid
      assetId
      startTs
      stopTs
      state
      onRoadDistance
      offRoadDistance
      totalDistanceTraveled
      movedToCoordinate {
        latitude
        longitude
        __typename
      }
      movedFromCoordinate {
        latitude
        longitude
        __typename
      }
      prevProjectId
      destProjectId
      customerId
      firstMove
      userGroups
      adminGroups
      assetTypeId
      assetType {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      assetSubTypeId
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssetMovementReport = /* GraphQL */ `
  mutation DeleteAssetMovementReport(
    $input: DeleteAssetMovementReportInput!
    $condition: ModelAssetMovementReportConditionInput
  ) {
    deleteAssetMovementReport(input: $input, condition: $condition) {
      id
      uuid
      assetId
      startTs
      stopTs
      state
      onRoadDistance
      offRoadDistance
      totalDistanceTraveled
      movedToCoordinate {
        latitude
        longitude
        __typename
      }
      movedFromCoordinate {
        latitude
        longitude
        __typename
      }
      prevProjectId
      destProjectId
      customerId
      firstMove
      userGroups
      adminGroups
      assetTypeId
      assetType {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      assetSubTypeId
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssetType = /* GraphQL */ `
  mutation CreateAssetType(
    $input: CreateAssetTypeInput!
    $condition: ModelAssetTypeConditionInput
  ) {
    createAssetType(input: $input, condition: $condition) {
      id
      name
      subType {
        nextToken
        __typename
      }
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssetType = /* GraphQL */ `
  mutation UpdateAssetType(
    $input: UpdateAssetTypeInput!
    $condition: ModelAssetTypeConditionInput
  ) {
    updateAssetType(input: $input, condition: $condition) {
      id
      name
      subType {
        nextToken
        __typename
      }
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssetType = /* GraphQL */ `
  mutation DeleteAssetType(
    $input: DeleteAssetTypeInput!
    $condition: ModelAssetTypeConditionInput
  ) {
    deleteAssetType(input: $input, condition: $condition) {
      id
      name
      subType {
        nextToken
        __typename
      }
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssetSubType = /* GraphQL */ `
  mutation CreateAssetSubType(
    $input: CreateAssetSubTypeInput!
    $condition: ModelAssetSubTypeConditionInput
  ) {
    createAssetSubType(input: $input, condition: $condition) {
      id
      name
      typeId
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssetSubType = /* GraphQL */ `
  mutation UpdateAssetSubType(
    $input: UpdateAssetSubTypeInput!
    $condition: ModelAssetSubTypeConditionInput
  ) {
    updateAssetSubType(input: $input, condition: $condition) {
      id
      name
      typeId
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssetSubType = /* GraphQL */ `
  mutation DeleteAssetSubType(
    $input: DeleteAssetSubTypeInput!
    $condition: ModelAssetSubTypeConditionInput
  ) {
    deleteAssetSubType(input: $input, condition: $condition) {
      id
      name
      typeId
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDimensions = /* GraphQL */ `
  mutation CreateDimensions(
    $input: CreateDimensionsInput!
    $condition: ModelDimensionsConditionInput
  ) {
    createDimensions(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDimensions = /* GraphQL */ `
  mutation UpdateDimensions(
    $input: UpdateDimensionsInput!
    $condition: ModelDimensionsConditionInput
  ) {
    updateDimensions(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDimensions = /* GraphQL */ `
  mutation DeleteDimensions(
    $input: DeleteDimensionsInput!
    $condition: ModelDimensionsConditionInput
  ) {
    deleteDimensions(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSupplier = /* GraphQL */ `
  mutation CreateSupplier(
    $input: CreateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    createSupplier(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSupplier = /* GraphQL */ `
  mutation UpdateSupplier(
    $input: UpdateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    updateSupplier(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSupplier = /* GraphQL */ `
  mutation DeleteSupplier(
    $input: DeleteSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    deleteSupplier(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      email
      description
      status
      assetsVersion
      projects {
        nextToken
        __typename
      }
      assets {
        nextToken
        __typename
      }
      userGroups
      adminGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      email
      description
      status
      assetsVersion
      projects {
        nextToken
        __typename
      }
      assets {
        nextToken
        __typename
      }
      userGroups
      adminGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      email
      description
      status
      assetsVersion
      projects {
        nextToken
        __typename
      }
      assets {
        nextToken
        __typename
      }
      userGroups
      adminGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      name
      customerId
      client
      description
      boundaries {
        latitude
        longitude
        __typename
      }
      overlays {
        name
        __typename
      }
      startDate
      endDate
      status
      userGroups
      adminGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      name
      type
      customerId
      client
      description
      boundaries {
        latitude
        longitude
        __typename
      }
      overlays {
        name
        __typename
      }
      startDate
      endDate
      status
      userGroups
      adminGroups
      __typename
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      type
      customerId
      client
      description
      boundaries {
        latitude
        longitude
        __typename
      }
      overlays {
        name
        __typename
      }
      startDate
      endDate
      status
      userGroups
      adminGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
