import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import dayjs from 'dayjs'
import moment from 'moment'
import SystemIcon from '../elements/SystemIcon'
import { useNavigate } from 'react-router-dom'

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Project/Yard Name',
    width: 355,
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    width: 40,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    width: 40,
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date Range',
    width: 120,
  },
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const styles = {
  tableCell: {
    color: 'white',
    borderBottom: '1px solid gray',
  },
}

const ProjectTable = ({ projects }) => {
  const navigate = useNavigate()

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('status')

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property)
  }

  const projectsSorted = useMemo(
    () => [...projects].sort(getComparator(order, orderBy)),
    [order, orderBy, projects],
  )

  return (
    <Grid2>
      <Typography color='secondary' variant='h6'>
        PROJECTS/YARDS
      </Typography>
      <TableContainer sx={{ marginTop: 5 }}>
        <Table>
          {/* Table Header */}
          {/* TODO: ask for the sorted option and style in general */}
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={styles.tableCell}
                  width={headCell.width}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    sx={{
                      color: 'white',
                      '&.Mui-active': {
                        color: 'white',
                      },
                      '& .MuiTableSortLabel-icon': {
                        color: 'white',
                      },
                      '&.Mui-active .MuiTableSortLabel-icon': {
                        color: 'white',
                      },
                      '&:hover': {
                        color: 'gray',
                      },
                    }}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell width={10} sx={styles.tableCell}></TableCell>
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {projectsSorted.map((project) => (
              <TableRow
                tabIndex={-1}
                key={project.id}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/Projects/' + project.id)
                }}
              >
                <TableCell
                  component='th'
                  id='name'
                  sx={{ paddingY: 4, ...styles.tableCell }}
                >
                  {/* TODO: ask for the image */}
                  {project.name}
                </TableCell>
                <TableCell component='th' id='type' sx={styles.tableCell}>
                  {/* {project?.type?.charAt(0).toUpperCase() + project?.type?.slice(1)} */}
                  Project
                </TableCell>
                <TableCell component='th' id='status' sx={styles.tableCell}>
                  {/* {project?.status?.charAt(0).toUpperCase() +
                    project?.status?.slice(1)} */}
                    Active
                </TableCell>
                <TableCell component='th' id='date' sx={styles.tableCell}>
                  {`${dayjs(moment(project.startDate).toDate())
                    .format('DD/MM/YYYY')
                    .toString()} - ${dayjs(moment(project.endDate).toDate())
                    .format('DD/MM/YYYY')
                    .toString()}`}
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Button
                    color='secondary'
                    variant='outlined'
                    data-test='projectCreateButton'
                    sx={{ borderRadius: 5 }}
                    startIcon={<SystemIcon name='icn_pencil' color='white' />}
                    onClick={(event) => {
                      event.stopPropagation()
                      //TODO: edit project page
                      navigate(`/Project/${project.id}`)
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid2>
  )
}

export default ProjectTable
