/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useRef } from 'react'
import {
  Divider,
  Button,
  Grid2,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  FormHelperText,
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import SystemIcon from './SystemIcon.js'
import { useProjectActions } from '../../store/Project/ProjectActions.js'
import {
  canceledPromiseErrorName,
  useCancellablePromise,
} from '../../promiseUtils.js'

const useStyles = makeStyles((theme) => ({
  clientSelect: {
    color: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '& .MuiSvgIcon-root': {
      color: theme.colors.lightSteel,
    },
    '& .MuiSelect-select': {
      color: 'white',
    },
  },
  menuItemButtonWrap: {
    pointerEvents: 'none',
    '&:hover': {
      background: 'none',
    },
    '& button': {
      pointerEvents: 'all',
    },
  },
  cancelButtonWrap: {
    marginTop: 19,
  },
}))

const ClientPicker = ({ error, helperText, value, onChange = () => {} }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { cancellablePromise } = useCancellablePromise()
  const [addClientMode, setAddClientMode] = useState()
  const [clientList, setClientList] = useState()

  const { getClientList } = useProjectActions()

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  const newClient = () => {
    setAddClientMode(true)
    onChange('')
  }

  const cancelNewClient = () => {
    setAddClientMode(false)
    onChange('')
  }

  useEffect(() => {

    const clients = getClientList()
    setClientList(clients ?? [])
    if (value && clients.indexOf(value) < 0) {
      setAddClientMode(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid2 container spacing={1} alignItems='flex-start'>
      {!addClientMode && (
        <>
          <Grid2 sx={{ width: '100%' }}>
            <FormControl
              error={error}
              variant='outlined'
              fullWidth
              margin='normal'
            >
              <InputLabel
                id='client-select-label'
                sx={{
                  color: 'white',
                  '&.Mui-focused': {
                    color: 'white',
                  },
                }}
              >
                Client
              </InputLabel>
              <Select
                id='client-select'
                value={value}
                onChange={handleChange}
                displayEmpty
                className={classes.clientSelect}
                labelId='client-select-label'
                label='Client'
              >
                {clientList &&
                  clientList.length > 0 &&
                  clientList.map((client) => (
                    <MenuItem
                      key={client}
                      value={client}
                    >{`${client}`}</MenuItem>
                  ))}
                {clientList && clientList.length > 0 && (
                  <Divider orientation='horizontal' />
                )}
                <MenuItem
                  disableRipple
                  value={null}
                  className={classes.menuItemButtonWrap}
                >
                  <Button onClick={newClient}>New Client</Button>
                </MenuItem>
              </Select>
              {helperText && <FormHelperText>{`${helperText}`}</FormHelperText>}
            </FormControl>
          </Grid2>
        </>
      )}
      {addClientMode && (
        <>
          <Grid2>
            <TextField
              autoFocus
              error={error}
              fullWidth
              helperText={helperText}
              label={'New Client'}
              margin='normal'
              value={value}
              variant='outlined'
              onChange={handleChange}
              sx={theme.textfield}
            />
          </Grid2>
          <Grid2 className={classes.cancelButtonWrap}>
            <IconButton
              data-test='cancelNewClientButton'
              onClick={cancelNewClient}
            >
              <SystemIcon name='icn_x' color='secondary' />
            </IconButton>
          </Grid2>
        </>
      )}
    </Grid2>
  )
}

export default ClientPicker
